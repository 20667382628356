
function dataUrl(file, callback) {
    if (!file || !window.FileReader) {
        callback && callback();
        return;
    }
    var reader = new FileReader();
    reader.addEventListener('load', e => {
        // console.log(reader.result);
        callback && callback(reader.result);
    })
    reader.readAsDataURL(file);
}

/*
void ctx.drawImage(image, dx, dy);
void ctx.drawImage(image, dx, dy, dWidth, dHeight);
void ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
*/
let index = 1;
export function compress(url, callback, max_size = 2400, thumb_size = 240, type = 'image/jpeg') {

    const image = document.createElement('img');
    const image_load = () => {
        image.removeEventListener('load', image_load);
        const naturalHeight = image.naturalHeight;
        const naturalWidth = image.naturalWidth;
        const scale = max_size && max_size < Math.max(naturalHeight, naturalWidth) ? max_size / Math.max(naturalHeight, naturalWidth) : 1;
        const height = Math.floor(naturalHeight * scale);
        const width = Math.floor(naturalWidth * scale);
        const canvas = document.createElement('canvas');

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, naturalWidth, naturalHeight, 0, 0, width, height);
        canvas.toBlob(file => {
            file.name = Date.now() / 1000 + "_" + (index += 1) + (type === 'image/jpeg' ? '.jpg' : '.png');
            dataUrl(file, b64 => {
                const scale = thumb_size < Math.max(naturalHeight, naturalWidth) ? thumb_size / Math.max(naturalHeight, naturalWidth) : 1;
                const height_t = Math.floor(naturalHeight * scale);
                const width_t = Math.floor(naturalWidth * scale);
                canvas.width = width_t;
                canvas.height = height_t;
                ctx.drawImage(image, 0, 0, naturalWidth, naturalHeight, 0, 0, width_t, height_t);
                canvas.toBlob(thumbFile => {
                    thumbFile.name = Date.now() / 1000 + "_" + (index += 1) + (type === 'image/jpeg' ? '.jpg' : '.png');
                    callback && callback({
                        file,
                        b64,
                        thumbFile,
                    })
                }, type, 0.85);
            })
        }, type, 0.85);

    }
    image.addEventListener('load', image_load);
    image.src = url;

}

export function compressfile(file, callback, max_size) {
    dataUrl(file, url => {
        compress(url, result => {
            if (result.file && file.name) {
                let name = file.name.substring(0, file.name.lastIndexOf('.'));
                result.file.name = name + result.file.name.substring(result.file.name.lastIndexOf('.'));
            }
            callback && callback(result);
        }, max_size)
    })
}
