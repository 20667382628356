<template>
  <div class="container flex-column" style="min-height: 100vh">
    <div class="header">
      <div class="wrap">
        <div class="title flex-row flex-center">
          <router-link to="/" class="home">
            <div class="logo">
              <img :src="require('../assets/logo.jpg')" />
            </div>
            <span class="title-text">
              中医临床药学服务<br />与调剂教育平台
            </span>
          </router-link>
          <ul class="nav-menu flex-row justify-evenly flex1">
            <li
              v-for="(item, index) in links"
              :key="index"
              :class="{ active: item.active }"
            >
              <router-link
                :to="item.url"
                :class="{ active: item.url === '/coursedetail' }"
                >{{ item.name }}</router-link
              >
            </li>
          </ul>
          <Avatar :updataTime="updataTime" :nickname="user.nickname" :imgUrl="imgUrl" style="margin-right: 37px" />
        </div>
      </div>
    </div>
    <div style="display: flex; margin-top: 1.5rem" class="wrap">
      <div
        style="
          width: 17rem;
          height: 30rem;
          background: #fff;
          border-radius: 6px;
        "
      >
        <div class="info_avatar">
          <img v-if="user.headImgUrl" :src="user.headImgUrl"  />
          <h2>{{ user.nickname }}</h2>
        </div>
        <div v-for="(item, index) in areaArr" :key="index">
          <div
            @click="changeTab(item.myIndex)"
            :style="`
           border-left:${
             activekey === item.myIndex ? '3px' : '0px'
           } solid orange;
           background:${activekey === item.myIndex ? '#f9ecd9' : '#fff'};
           padding-left:${activekey === item.myIndex ? '45px' : '48px'} `"
            class="tabs"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- ${collecOpcity ? `collectClass` : `originClass`} -->
      <div :class="`originClass`">
        <div v-if="activekey === '1'">
          <div v-if="!zoneArr.length" style="width: 840px;display:flex;flex-direction: column">
            <div class="rightContent" style="padding:1rem;height:54px;display: flex;flex-direction: row-reverse" >
              <div style="text-align:center;cursor:pointer" @click="joinZone" v-if="!joinStatus">
                <img style="width:35px" :src="require('../assets/addZone.png')" alt="">
                <p>加入专区</p>
              </div>
               <div @click="joinZone"  v-else style="color:#ffba00;cursor:pointer;margin-right:20px">
                 <img style="transform: rotate(270deg);" :src="require('../assets/zhankai.png')" alt="">
                 <p>返回</p>
               </div>
            </div>
            <div class="rightContent" style="margin-top:10px;min-height:300px;padding:1rem">
              <div v-if="!joinStatus">
                <p v-if="!zoneApplyObj.applyStatus">您还没有加入专区，请先加入专区</p>
                <!-- zoneApplyObj -->
                <div class="zoneItem" v-if="zoneApplyObj.applyStatus">
                    <div class="d-flex" style="width:100%;height:150px;border-radius:6px;overflow:hidden;">
                      <div >
                        <img style="width:360px;height:150px;object-fit:cover" :src="zoneApplyObj.zoneImgUrl" alt="">
                      </div>
                      <div style="padding-top:0.6rem;" class="rightDesc" >
                        <div  class="ellipsis d-flex" style="justify-content: space-between;"><div>{{zoneApplyObj.zoneName}}</div> <div style="font-size:17px;color:red">{{zoneApplyObj.applyStatus && '审核中'}}</div></div>
                        <p style="padding-top:0.5rem;" class="ellipsis">{{zoneApplyObj.hospitalName || '&nbsp;'}}</p>
                        <p style="padding-top:0.5rem;" class="ellipsis3">{{zoneApplyObj.profile}}</p>
                      </div>
                    </div>
                    
                    <div v-if="zoneApplyObj.applyStatus"  class="zoneItemCover" @click="applyCancel(zoneApplyObj)">
                      <div class="d-flex flex-center " style="height:100%">
                        <p>撤销专区</p> 
                      </div>
                    </div>
                  </div>
              </div>
              <div v-else>
                <div class="search">
                  <div class="search_main">
                    <div class="input_d">
                      <img :src="require('../assets/search.png')" alt="">
                      <input placeholder="请输入搜索关键词"  @keydown="enter" />
                    </div>
                    
                  </div>
                </div>
                <p style="margin-left:76px">您还没有加入专区，请先加入专区</p>

                <div style="padding: 1rem;  display: flex; flex-direction: column; align-items: center;">
                  <div class="zoneItem" v-for="(item,index) in zoneAll" :key="index">
                    <div class="d-flex" style="width:100%;height:150px;border-radius:6px;overflow:hidden;">
                      <div >
                        <img style="width:360px;height:150px;object-fit:cover" :src="item.zoneImgUrl" alt="">
                      </div>
                      <div style="padding-top:0.6rem;" class="rightDesc" >
                        <div  class="ellipsis">{{item.zoneName}}</div>
                        <p style="padding-top:0.5rem;" class="ellipsis">{{item.hospitalName || '&nbsp;'}}</p>
                        <p style="padding-top:0.5rem;" class="ellipsis3">{{item.profile}}</p>
                      </div>
                    </div>
                    <div v-if="!item.applyStatus" class="zoneItemCover" @click="applyZoneModalOpen(item)">
                      <div class="d-flex flex-center " style="height:100%">
                        <p>申请专区</p> 
                      </div>
                    </div>
                    <div v-else class="zoneItemCover" @click="applyCancel(item)">
                      <div class="d-flex flex-center " style="height:100%">
                        <p>撤销专区</p> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else style="width: 840px;display:flex;flex-direction: column">
              <div class="rightContent" style="background:#fff;padding: 1.5rem;min-height:600px" >
                <div style="display:flex;justify-content: center;">

                  <div class="zoneItem" v-for="(item,index) in zoneArr" :key="index">
                    <div class="d-flex" style="width:100%;height:270px;border-radius:6px;overflow:hidden;">
                      <div >
                        <img style="width:422px;height:270px;object-fit:cover" :src="item.zoneImgUrl || ''" alt="">
                      </div>
                      <div class="rightDesc" >
                        <div class="ellipsis">{{item.zoneName || ''}}</div>
                        <p class="ellipsis">{{item.hospitalName || '&nbsp;'}}</p>
                        <p class="ellipsis3">{{item.profile || ''}}</p>
                      </div>
                    </div>
                    <div class="zoneItemCover" @click="goZone(item)">
                      <div class="d-flex flex-center " style="height:100%">
                        <p>进入专区</p> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <!-- 我的收藏 -->
        <div v-if="activekey === '2'">
          <div  class="myCollect" style="padding:1rem;background:#fff;">
            <p style="line-height:35px">筛选：</p>
            <!-- collectArr -->
            <div v-for="(item, index) in collectArr" :key="index">
              <div
                @click="myCollect(item.collectIndex)"
                :style="`
                  cursor:pointer;
                border-bottom:${
                  collectkey === item.collectIndex ? '3px' : '0px'
                } solid #FF9552;
                color:${
                  collectkey === item.collectIndex ? '#FF9552' : '#000'
                };padding-bottom: 5px;`"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <!-- <div style="width:100%;box-sizing:border-box;background:#888;height:1rem"></div> -->
          <div class="rightContent" style="margin-top:10px;min-height:300px;padding:1rem" v-if="collectkey == '1'">
            <div style="margin-top: 2rem;color:#999">
              内容数量：{{ courseCollectionNum }}
            </div>
            <div style="display: flex; width: 840px; flex-wrap: wrap">
              <div
                class="articleStyle"
                v-for="(item, index) in courseCollections"
                :key="index"
                @click="toCourse(item)"
              >
                <div style="width: 100%; border-radius: 6px; position:relative">
                  <div class="cancelCollect" @click="removeCollect(item.id)">
                    <div class="d-flex flex-center">
                      <img style="width:12px;margin-right:2px;" :src="require('../assets/delete.png')" alt="">
                      <p>移除</p> 
                    </div>
                  </div>
                  <img
                    style="width: 12rem; height: 7rem; object-fit: cover"
                    :src="item.imgUrl"
                  />
                </div>
                <div style="display: flex; flex-direction: column">
                  <div class="ellipsis2 articleName">{{ item.courseName }}</div>
                  <div class="articleOther">
                    <div>{{ item.createTime }}</div>
                    <div style="display: flex">
                      <img
                        style="width: 1rem; margin-right: 0.1rem"
                        :src="require('../assets/eyeOpen.png')"
                        alt=""
                      />
                      <p>{{ item.numberOfView }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rightContent" style="margin-top:10px;min-height:300px;padding:1rem" v-if="collectkey == '2'">
            <div style="margin-top: 2rem;color:#999">
              内容数量：{{ articleCollectionNum }}
            </div>
            <div style="display: flex; width: 840px; flex-wrap: wrap">
              <div
                class="articleStyle"
                v-for="(item, index) in articleCollections"
                :key="index"
                @click="toArticle(item)"
              >
                <div style="width: 100%; border-radius: 6px;position:relative">
                  <div class="cancelCollect" @click="removeArticle(item.id)">
                    <div class="d-flex flex-center">
                      <img style="width:12px;margin-right:2px;" :src="require('../assets/delete.png')" alt="">
                      <p>移除</p> 
                    </div>
                  </div>
                  <img
                    style="width: 12rem; height: 7rem; object-fit: cover"
                    :src="item.headImgUrl"
                  />
                </div>
                <div style="display: flex; flex-direction: column">
                  <div class="ellipsis2 articleName">
                    {{ item.articleName }}
                  </div>
                  <div class="articleOther">
                    <div>{{ item.webPublishTime }}</div>
                    <div style="display: flex">
                      <img
                        style="width: 1rem; margin-right: 0.1rem"
                        :src="require('../assets/eyeOpen.png')"
                        alt=""
                      />
                      <p>{{ item.numberOfView }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="collectkey == '3'"></div>
        </div>
        <div v-if="activekey === '3'">
          <div class="rightContent" style="height: 35px;line-height:35px;padding:1rem;background:#fff;">
               <p>个人资料</p>
          </div>
          <div class="rightContent" style="margin-top:10px;min-height:300px;padding:1rem">

            <div class="info_avatar_detail">
              <div class="avatar_wrap">
                <img v-if="user.headImgUrl" :src="user.headImgUrl" alt="" />
                <div
                  class="shadow"
                >
                  <p>修改头像</p>
                </div>
                <input
                  @mouseenter="shadowOpened"
                  @mouseleave="shadowOpened"
                  @change="selectImg"
                  type="file"
                  class="uploader_input"
                  accept=".jpg,.jpeg,.png,.gif"
                />
              </div>
              <h2 style="margin-top:5px">{{ user.nickname }}</h2>
            </div>
            <!-- 资料表单部分 -->
            <div class="info_table">
              <div>
                <p>手机号</p>
                <a style="margin-left: 8rem">{{ userTel }}</a>
                <a
                  style="color: orange; margin-left: 1rem; cursor: pointer"
                  @click="goChangePhone"
                  >修改</a
                >
              </div>
              <div>
                <p>性别</p>
                <label for="yes" style="margin-left: 8rem" class="choose-box"
                  ><input
                    type="radio"
                    id="yes"
                    @change="sexChoose"
                    value="1"
                    :checked="sex == '1'"
                    name="choose"
                  />男</label
                >
                <label for="no" style="margin-left: 2.5rem" class="choose-box"
                  ><input
                    type="radio"
                    id="no"
                    @change="sexChoose"
                    value="2"
                    :checked="sex == '2'"
                    name="choose"
                  />女</label
                >
              </div>
              <div>
                <p>用户名</p>
                <input
                  style="margin-left: 8rem"
                  type="text"
                  v-model="nickname"
                  maxlength="8"
                  placeholder="用户名，1~8个汉字/字符"
                />
              </div>

              <div class="saveBtn">
                <div @click="save">保存</div>
              </div>

            </div>
          </div>
        </div>
            <div v-show="msgState" style="height:143px;line-height:143px;font-size:19px" class='msgBox'><span style="width:100%">{{msg}}</span></div>
      </div>
    </div>
    <div class="applyZoneModal" v-if="applyZoneModal">
      <div class="applyZoneModalBox">
        <div><span @click="applyZoneModalClose">关闭</span></div>
        
        <div class="d-flex" style="width:100%;height:83px;border-radius:6px;overflow:hidden;">
          <div style="border-radius:6px;overflow: hidden;">
            <img style="width:150px;height:100%;object-fit:cover" :src="applyZoneModalItem.zoneImgUrl" alt="">
          </div>
          <div class="smallRightDesc" >
            <div class="ellipsis">{{applyZoneModalItem.zoneName}}</div>
            <p class="ellipsis">{{applyZoneModalItem.hospitalName || '&nbsp;'}}</p>
            <p class="ellipsis">{{applyZoneModalItem.profile}}</p>
          </div>
        </div>

        <div style="width:100%;height:1px;background:#dfdcdc;margin-top:6px"></div>
        <div class="d-flex" style="padding-top:10px"><p style="color:red;">*</p>申请原因</div>
        <div style="position:relative">
          <textarea  v-model="remark" id="txtArea" cols="30" rows="5" maxlength="30" ></textarea>
          <p style="position: absolute; right: 14px;bottom: 10px;">{{remark.length}}/30</p>
          <div style="position: absolute;font-size: 13px; color: red;" v-if="remarkState">请填写申请原因</div>
        </div>
        <div class="applyBtn" @click="apply"> 申请加入</div>
      </div>
    </div>
  </div>
</template>

<script >
import { reactive, toRefs, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ossUpload } from "../utils/OssFileUploader";
import { get, post, getLogindata } from "@/utils/common";
import { load } from "@/utils/storage";
import { moment_time } from "@/utils/helper";
import moment from "moment";
import router from '../router/index';

export default {
  name: "My",
  components: {},
  setup(props) {
    const route = useRoute();
    const state = reactive({
      recommend_list: [],
      remark:'',
      avatarModalStatus: false,
      loading: 0,
      activekey: route.query?.myIndex || "2",
      collectkey: "1",
      user: {},
      userTel:'',
      shadowOpen: false,
      nickname: "",
      sex: "",
      applyZoneModal:false,
      remarkState:false,
      zoneArr:[],
      msgState:false,
      msg:'',
      updataTime:null,
      collecOpcity: false,
      areaArr: [
        {
          myIndex: "1",
          name: "专区管理",
        },
        {
          myIndex: "2",
          name: "我的收藏",
        },
        {
          myIndex: "3",
          name: "资料与账号",
        },
      ],
      collectArr: [
        {
          collectIndex: "1",
          name: "课程",
        },
        {
          collectIndex: "2",
          name: "文章",
        },
        // {
        //   collectIndex: "3",
        //   name: "帖子",
        // },
      ],
      articleCollections: [],
      courseCollections: [],
      courseCollectionNum:0,
      articleCollectionNum:0,
      imgUrl:'',
      joinStatus: false,
      zoneAll:[],
      zoneAllCopy:[],
      applyZoneModalItem:{},
      zoneDisplay:false,
      zoneApplyObj:{}
    });
    onMounted(() => {
      load();
    });

    const load = () => {
      get("userInformation/getInformation", (res) => {
        const { nickname, sex, userTel } = res.data;
        state.nickname = nickname;
        state.user = res.data;
        state.userTel = userTel.substring(0, 3)+"****"+userTel.substr(userTel.length-4)
        state.sex = sex || "";
      });
      getCollect();
      get(`hospitalZoneWeb/getMyHospitalZone`,res=>{
        
        //res.data是个对象，后续可能会有多个专区，把数组符号去掉即可
        state.zoneArr = res.data ? [res.data] : [];
        state.zoneDisplay = state.zoneArr.length;
      })
      get(`hospitalZoneWeb/hospitalzones`,res=>{

        const newData = res.apply ? res.data.map(i=>{
          if (res.apply.zoneId === i.id){
            i.applyStatus = true;
          }
          return i
        }) : res.data
        state.zoneAll = newData;
        state.zoneApplyObj = newData.find(i=>i.applyStatus) || {};
        state.zoneAllCopy = newData;
      })
    };

    const enter = (e) => {
      console.log("event", e);
      if (e.keyCode == 13) {
        const val = (e.target.value || "").trim();
  
        state.zoneAll =  state.zoneAllCopy.filter(i=> i.zoneName.includes(val))
        // console.log('帅选后',state.zoneAll);
       
      }
    };

    const toCourse = (item) =>{
      document.documentElement.scrollTop = 0
      console.log('toCourse', item);
      router.push(!item.hospitalZoneId || item.hospitalZoneId === 1 ? "/course/" + item.id : `/zcourse/${item.hospitalZoneId}/${item.id }` );
    }

    const toArticle = (item) =>{
      document.documentElement.scrollTop = 0
      console.log('toArticle', item);
      router.push(!item.hospitalZoneId || item.hospitalZoneId === 1 ? "/article/" + item.id : `/zarticle/${item.hospitalZoneId}/${item.id }`);
    }

    const getCollect = () =>{
       post('userInformation/myCollections',{},res=>{
        
        state.articleCollections = res.articleCollections.map(i=>{
           i.webPublishTime ? moment(i.webPublishTime).format('YYYY-MM-DD HH:mm') : '';
           return i;
        });
        state.articleCollectionNum = res.articleCollectionNum;
        state.courseCollectionNum = res.courseCollectionNum;
        state.courseCollections = res.courseCollections.map((item) => {
          item.imgUrl = item.imgUrl ? JSON.parse(item.imgUrl)[0] : "";
          item.createTime = item.createTime ? moment(item.createTime).format('YYYY-MM-DD HH:mm') : '';
          return item;
        });;
      })
    }
    // watch(
    //   () => route.query,
    //   (newValue, oldValue) => {
    //     // console.log('watch新知',newValue);
    //     console.log("route", newValue, route, route.query?.myIndex);
    //     state.activekey = newValue.myIndex || '';
    //   },
    //   { immediate: true }
    // );

    const myCollect = (index) => {
      state.collectkey = index;
    };

    const removeCollect = (id) =>{
      event.stopPropagation();
        post(
          "collection/addRemoveCollection",
          { collectionType: 2, sourceId: id },
          (res) => {
            getCollect();
            state.msgState = true;
            state.msg = '已取消收藏';
            setTimeout(() => {
              state.msgState =false;
            }, 3000);
          }
        );
    }
    
    const removeArticle = (id) =>{
      //取消收藏文章   接口参数需要修改
      event.stopPropagation();
        post(
          "collection/addRemoveCollection",
          { collectionType: 1, sourceId: id },
          (res) => {
            getCollect();
            state.msgState = true;
            state.msg = '已取消收藏';
            setTimeout(() => {
              state.msgState =false;
            }, 3000);
          }
        );
    }

    const sexChoose = (e) => {
      state.sex = e.target.value;
    };
    
    const msgBox = (msg, time = 2000) =>{
      state.msgState = true;
      state.msg = msg;
      setTimeout(() => {
        state.msgState =false;
      }, time);
    }

    const applyZoneModalOpen = (item) =>{
      console.log('点的专区信息',item);
      state.applyZoneModalItem = item;
      state.applyZoneModal = true;
    }
    const applyCancel = (item) =>{
      post(
        "hospitalzone/updatecheckuserzone",
        {zoneId:item.id},
        res =>{
         msgBox(res.feedbackMsg)
         load();
        }
      )
    }
    
    const applyZoneModalClose = () =>{
      state.applyZoneModal = false;
      state.applyZoneModalItem = {};
      state.remark = '';
      state.remarkState = false;
    }
    
    const apply = () =>{
      if(!state.remark.trim().length){
        state.remarkState = true;
        return;
      }
      
      post(
        "hospitalZoneWeb/apply",
        {zoneId:state.applyZoneModalItem.id,remark:state.remark},
        res =>{
         applyZoneModalClose();
         msgBox('申请成功，请耐心等待审核')
         load();
        },err=>{
          applyZoneModalClose();
          msgBox(err);
        }
      )
    }

    const goZone = () =>{
      document.documentElement.scrollTop = 0;
      router.push({
        path:'/zone'
      })
    }

    const changeTab = (index) => {
      //我的收藏页面有特殊样式 需要把背景色变透明 重新写样式

      // index==1的时候点不动 后续有需求
      // if (index == "1") {
        // post(`hospitalZoneWeb/getIndex`,{},res=>{
        //   document.documentElement.scrollTop = 0;
        //   router.push({
        //     path:'/zone'
        //   })
        // },err=>{
        //   state.msgState = true;
        //   state.msg = err;
        //   setTimeout(() => {
        //     state.msgState =false;
        //   }, 3000);
        //   return;
        // })

        
      // }else{
        document.documentElement.scrollTop = 0
        router.push("/my?myIndex=" + index);
      // }
      // if(index === '2'){
      //   state.collecOpcity = true;
      // }else{
      //   state.collecOpcity =false;
      // }
      // // console.log(index, "dssa");
      // state.activekey = index;r
      // console.log(state.activekey);
    };

    const avatarModal = () => {
      state.avatarModalStatus = true;
    };

    const avatarModalLeave = () => {
      state.avatarModalStatus = false;
    };
    
    const selectImg = (e) => {
      console.log("selectImg", e);
      const imgFile = e.target.files[0];
      if (imgFile) {
        if (checkFile(imgFile)) {
          ossUpload(imgFile, (res) => {
            console.log("图片上传 ==>", res, imgFile);
            state.user.headImgUrl = res.url;
            state.imgUrl = res.url
            save();
          });
        }
      }
    };
    const goChangePhone = () => {
      document.documentElement.scrollTop = 0
      router.push({
        name: "ChangePhone",
        params: {
          phoneNumber: state.user.userTel
        },
      });
    };
    const shadowOpened = () => {
      state.shadowOpen = !state.shadowOpen;
    };
    const checkFile = (file) => {
      if (file === null || file === undefined) {
        // Dialog.alert({
        //     title: '错误',
        //     message: "请选择您要上传的文件！",
        // });
        return false;
      }

      return true;
    };
    const save = () => {
      //照片
      if (state.nickname.length > 8) {
        msgBox('昵称不能超过8个字')
        return;
      }
      post(
        "userInformation/updateInformation",
        {
          nickname: state.nickname || state.user.nickname,
          headImgUrl: state.user.headImgUrl,
          sex: state.sex,
        },
        (res) => {
          state.msgState = true;
          state.msg = res.feedbackMsg;
          state.user.nickname = state.nickname || state.user.nickname;
          state.imgUrl = state.user.headImgUrl;
          state.updataTime = Date.now();
          console.log(Date.now());
          setTimeout(() => {
            state.msgState =false;
          }, 1500);
        }
      );
    };
    const joinZone = () =>{
      state.joinStatus = !state.joinStatus;

    }
    return {
      ...toRefs(state),
      avatarModal,
      avatarModalLeave,
      selectImg,
      changeTab,
      goZone,
      applyZoneModalOpen,
      applyCancel,
      applyZoneModalClose,
      apply,
      goChangePhone,
      save,
      toCourse,
      toArticle,
      sexChoose,
      myCollect,
      removeCollect,
      shadowOpened,
      enter,
      removeArticle,
      joinZone
    };
  },
};
</script>

<style lang="less" scoped>
// .group
.tabs {
  margin-top: 1rem;
  padding-left: 48px;
  line-height: 3rem;
  height: 3rem;
  cursor: pointer;
}
.articleStyle {
  width: 12rem;
  height: 13rem;
  overflow: hidden;
  margin-left: 1rem;
  margin-top: 1rem;
  border-radius: 6px;
  box-shadow: 3px 3px 6px #888888;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  .articleName {
    margin: 13px 8px 0rem 8px ;
    padding-bottom: 1rem;
    height: 26px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
  }
  .articleOther {
    display: flex;
    font-size: 12px;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    opacity: 0.5;
    padding: 0.6rem 0.3rem;
  }
  &:hover{
    .cancelCollect{
      display: block;
    }
  }
}
.myCollect {
  display: flex;
  border-radius: 5px;
  height: 35px;
  width: 100%;
  div {
    margin-left: 3rem;
    display: flex;
    p {
      cursor: pointer;
      margin-left: 3rem;
    }
  }
}
.originClass {
  flex: 1;
  // margin-left: 1.5rem;
  // background: #fff;
  padding: 0rem 1rem;
  border-radius: 6px;
  position: relative;
  .rightContent{
    border-radius:5px;
    width:100%;
    background:#fff;
  }
}
.collectClass {
  flex: 1;
  margin-left: 1.5rem;
  opacity: 0;
  padding: 1rem 2rem;
  border-radius: 6px;
}
// 单选框样式
.choose-box {
  padding-left: 40px;
  position: relative;
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  cursor:pointer
}
.choose-box input[type="radio"]::before {
  content: "";
  display: inline-block;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid #ff9552;
  margin-right: 15px;
  position: absolute;
  top: 7px;
  left: 10px;
  cursor:pointer
}
.choose-box input[type="radio"]:checked::before {
  background-clip: content-box;
  background-color: #ff9552;
  width: 11px;
  height: 11px;
  padding: 4px;
}
.choose-box input[type="radio"] {
  width: 0;
  height: 0;
}
.info_avatar {
  width: 100%;
  height: 13rem;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    margin-bottom: 1rem;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    // background: orange;
  }
}
.info_avatar_detail {
  width: 840px;
  height: 15rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  // img:hover{
  //   background:#505050;
  //   opacity: 0.5;
  // }
}
.msgBox {
  position: absolute;
  top: 255px;
  background: rgba(76, 76, 76, .9);
  border-radius: 10px;
  width: 99px;
  color: #fff;
  width: 317px;
  
  text-align: center;
  left: 290px;
}
.info_table {
  padding: 1rem 2rem;
  position: relative;
  div {
    display: flex;
    margin-top: 1rem;
    height: 2rem;
    line-height: 2rem;
    p {
      color: #888888;
      width: 8rem;
      text-align: right;
    }
    input {
      border: 1px solid #d3d3d3;
      border-radius: 5px;
      padding-left: 10px;
    }
    input::-webkit-input-placeholder {
      color: #e0e0e0;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #e0e0e0;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #e0e0e0;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #e0e0e0;
    }
  }
}
.saveBtn {
  height: 3rem;
  padding: 2rem 0rem;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-direction: row-reverse;
  div {
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 5rem;
    border-radius: 5px;
    color: #fff;
    background: #ff9552;
    font-size: 14px;
  }
}
.userModal {
  position: absolute;
  top: 50px;
  background: #fff;
  width: 10rem;
  height: 15rem;
  padding: 1rem;
  box-shadow: 2px 2px 3px #888888;
}
.group div:hover {
  color: orange;
  cursor: pointer;
}
// .search{
//   width: 90%;

// }
.avatar_wrap {
  width: 8rem;
  height: 8rem;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0;
    border-image-width: 0;
  }
  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(100, 100, 100, .5);
    cursor: pointer;
    color: #fff;
  }
  .uploader_input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: transparent;
    padding-left: 100%;
    box-sizing: content-box;
  }
  &:hover{
    .shadow{
      display: flex;
    }
  }
}
.cancelCollect{
  position: absolute;
  z-index: 9999;
  padding: 4px 6px;
  right: 10px;
  top: 5px;
  background: rgba(59, 52, 52,.5);
  color: #fff;
  border-radius:5px ;
  font-size: 12px;
  display: none;
}
.container {
  max-width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  background: #f5f5f5;
}
.zoneItem{
  width:100%;
  margin-top:20px;
  box-shadow: 3px 3px 6px #888888;
  border-radius: 6px;
  position: relative;
  .zoneItemCover{
    position: absolute;
    z-index: 9999;
    // padding: 4px 6px;
    cursor: pointer;
    right: 0;
    top: 0;
    left:0;
    bottom: 0;
    background: rgba(59, 52, 52,.5);
    color: #fff;
    border-radius:6px ;
    font-size: 23px;
    display: none;
  }
  .rightDesc{
    flex: 1;
    padding: 1.5rem 0.6rem 0.6rem 0.6rem;
    div{
      font-size: 20px;
    }
    p{
      padding-top:1.5rem;
    }
  }
  &:hover{
    .zoneItemCover{
      display: block;
    }
  }
}
.search {
  width: 100%;
  position: relative;
  // background: #999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .search_main {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .input_d {
      width: 650px;
      height: 45px;
      border-radius: 20px;
      margin-bottom: 15px;
      overflow: hidden;
      img{
        position: absolute;
        top: 8px;
        left: 111px;
      }
      input {
        width: 100%;
        height: 100%;
        padding-left: 50px;
        background: rgba(153, 153, 153,.2);
        font-size: 15px;
      }

      input::-webkit-input-placeholder {
        color: #999;
        // font-weight: bold;
      }
      input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #999;
        // font-weight: bold;
      }
      input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #999;
        // font-weight: bold;
      }
      input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #999;
        // font-weight: bold;
      }
    }

    
  }
}

.applyZoneModal{
  position: fixed;
  z-index: 20000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(100, 100, 100, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  .applyZoneModalBox{
    width: 300px;
    // height: 320px;
    background: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    .smallRightDesc{
      width:130px;
      padding: 0.3rem;
      div{
        font-size: 16px;
      }
      p{
        padding-top:5px;
      }
    }
    span{
      float:right;
      cursor:pointer;
      color:#ff9552
    }
  }
  #txtArea{
    padding:1rem;
    font-size: 18px;
    margin-top: 20px;
    height: 63px;
    width: 266px;
    background:rgb(242,242,242) ;
  }
  .applyBtn{
    width:100%;
    height:40px;
    background:#ff9552;
    color:#fff;
    line-height:40px;
    text-align:center;
    font-size: 17px;
    margin-top: 30px;
    border-radius: 28px;
  }
}
</style>
