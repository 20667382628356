import { get, fixurl } from './common';
import request from 'superagent';
import { compressfile } from './imageutil';

let url_oss_token = 'ossServer/getSign';

export function set_url_oss_token(url) {
    url_oss_token = url;
}


/**
 * 
 * @param {要上传的文件} file 
 * @param {上传回调} callback  { url, name, error }
 * @param {图片自动压缩未jpg格式，这里设置最大 宽/高 度，处理的图片格式为 png, bmp} image_max_width , 默认 1600px，如果需要保持图片分辨率，可以设置为 0 
 */
export function ossUpload(file, callback, url_oss, image_max_width = 1600) {
    if (/(\.png$)|(\.bmp$)|(\.jpg$)/i.test(file.name)) {
        compressfile(file, res => {
            ossUploadRare(res.file, callback, url_oss);
        }, image_max_width);
        return;
    }
    return ossUploadRare(file, callback, url_oss);
}

/**
 * 
 * @param {要上传的文件} file 
 * @param {上传回调} callback  { url, name, error }
 */
export function ossUploadRare(file, callback, url_oss) {
    var file_name = file.name;
    var file_suffix = file_name.split('.');
    file_suffix = file_suffix.length > 1 ? '.' + file_suffix[file_suffix.length - 1] : '';
    var url = url_oss ? url_oss : url_oss_token;

    get(url, ({ data }) => {
        var obj_key = data.dir + '/' + data.id + file_suffix;
        request.post(fixurl(data.host))
            .field('key', obj_key)
            .field('policy', data.policy)
            .field('OSSAccessKeyId', data.accessid)
            .field('success_action_status', '200')
            .field('signature', data.signature)
            .attach('file', file)
            .then(() => {
                var url = data.host.replace(/^https?:/i, '') + '/' + obj_key;
                // console.log('callback', url, file_name)
                callback && callback({
                    url: url,
                    name: file_name
                });
            })
            .catch(error => {
                callback && callback({
                    error
                })
            });

    }, error => {
        callback && callback({
            error
        })
    })
}

export const dataUrl = (file, callback) => {
    if (!file || !window.FileReader) {
        callback && callback();
        return;
    }
    var reader = new FileReader();
    reader.addEventListener('load', e => {
        // console.log(reader.result);
        callback && callback(reader.result);
    })
    reader.readAsDataURL(file);
}
